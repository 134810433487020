<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-berth-investment-wrap"
  >
    <div class="w-berth-investment-main">
      <div class="info-wrap">
        <div class="info">
          <div class="info-line1">
            <div class="berthNo">{{berthInfo.berthNo ? berthInfo.berthNo : '-'}}</div>
            <div class="state">{{berthInfo.stateName ? berthInfo.stateName : '-'}}</div>
            <div class="title mr-16">招商负责人：<span>{{berthInfo.principalName ? berthInfo.principalName : '-'}}</span></div>
            <div class="title mr-32">铺位招商时效：<span>{{berthInfo.brandBerthTime ? berthInfo.brandBerthTime : '-'}}</span></div>
            <div
              class="btn"
              @click="showEditInfoDialog"
            >编辑</div>
          </div>
          <div class="info-line2 mb-16">
            <div class="title">楼宇楼层：<span>{{(berthInfo.building || berthInfo.floor) ? berthInfo.building + berthInfo.floor : '-'}}</span></div>
            <div class="title">店铺类型：<span>{{berthInfo.berthType === 1 ? '铺位' : (berthInfo.berthType === 2 ? '广告位' : (berthInfo.berthType === 3 ? '场地' : (berthInfo.berthType === 4 ? '写字楼' : '-')))}}</span></div>
            <div class="title">建筑面积：<span>{{berthInfo.buildingArea ? berthInfo.buildingArea + '㎡' : '-'}}</span></div>
            <div class="title">使用面积：<span>{{berthInfo.useArea ? berthInfo.useArea + '㎡' : '-'}}</span></div>
          </div>
          <div class="info-line2">
            <div class="title">装修情况：<span>{{berthInfo.decoration ? berthInfo.decoration : '-'}}</span></div>
            <div class="title">承重：<span>{{berthInfo.bearing ? berthInfo.bearing + '（㎏/㎡）' : '-'}}</span></div>
            <div class="title">柱距：<span>{{berthInfo.columnSpacing ? berthInfo.columnSpacing + '（m）' : '-'}}</span></div>
            <div class="title">层高：<span>{{berthInfo.floorHeight ? berthInfo.floorHeight + '（m）' : '-'}}</span></div>
            <div class="title">供电容量：<span>{{berthInfo.powerSupplyCapacity ? berthInfo.powerSupplyCapacity + '（kw）' : '-'}}</span></div>
            <div class="title">新风量：<span>{{berthInfo.freshAirVolume ? berthInfo.freshAirVolume + '（m³/min）' : '-'}}</span></div>
            <div class="title">设施情况：<span>{{berthInfo.facilitiesCondition ? berthInfo.facilitiesCondition : '-'}}</span></div>
          </div>
        </div>
        <div
          class="status"
          :class="berthInfo.taskState !== '继续招商中' ? 'w100' : 'w150'"
        >
          {{berthInfo.taskState ? berthInfo.taskState : '-'}}
        </div>
      </div>
      <div class="w-table-content">
        <div class="w-screen-title">
          <h3 class="w-title-gradients">铺位租决信息</h3>
        </div>
        <div class="w-berth-info">
          <table>
            <tr>
              <td>规划业态</td>
              <td>{{berthRentInfo.industry1?berthRentInfo.industry1:'-'}}</td>
              <td>主次力店</td>
              <td>{{berthRentInfo.mainStore === 1 ? '主力店' : (berthRentInfo.mainStore === 2 ? '次主力店' : (berthRentInfo.mainStore === 0 ? '普通店' : '-'))}}</td>
              <td>计租面积类型</td>
              <td>{{berthRentInfo.areaType?(berthRentInfo.areaType === 1 ? '建筑面积' : '使用面积'):'-'}}</td>
              <td>计租面积</td>
              <td>{{berthRentInfo.area?berthRentInfo.area + '㎡':'-'}}</td>
              <td>租赁年限</td>
              <td>{{berthRentInfo.rentYear?berthRentInfo.rentYear:'-'}}</td>
              <td>招商品牌级次</td>
              <td>{{berthRentInfo.brandGradeType?berthRentInfo.brandGradeType:'-'}}</td>
            </tr>
            <tr>
              <td>免租时长</td>
              <td>{{berthRentInfo.rentFreeTime?berthRentInfo.rentFreeTime:'-'}}</td>
              <td>租金递增率</td>
              <td>{{berthRentInfo.rentIncreasingRate?berthRentInfo.rentIncreasingRate:'-'}}</td>
              <td>租金市场价</td>
              <td>{{berthRentInfo.marketPrice?berthRentInfo.marketPrice + '元/月/㎡':'-'}}</td>
              <td>租金标准价</td>
              <td>{{berthRentInfo.rentPrice?berthRentInfo.rentPrice + '元/月/㎡':'-'}}</td>
              <td>租金保底价</td>
              <td>{{berthRentInfo.bottomPrice?berthRentInfo.bottomPrice + '元/月/㎡':'-'}}</td>
              <td>{{user.topuid === 6467 ? '物业' : '综合'}}管理费</td>
              <td>{{berthRentInfo.managementCost?berthRentInfo.managementCost + '元/月/㎡':'-'}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="tab-wrap">
        <div class="tab fl">
          <ul class="fl">
            <li
              v-for="(item, index) in tabList"
              :key="item.value"
              :class="{'active' : tabValue === item.value}"
              @click="tabSelect(index)"
            >
              <span>{{item.label}}</span>
            </li>
          </ul>
          <div class="btns-wrap fr">
            <span
              v-for="(item, index) in btnsList"
              :key="index"
              @click="btnClick(index)"
            >
              <i :class="[item.icon]"></i>
              {{item.name}}</span>
          </div>
        </div>
        <!-- 铺位签约信息 -->
        <div
          v-if="tabValue === 0"
          class="table-wrap"
        >
          <div class="w-singed-table">
            <el-table
              :data="signList.dataList"
              style="width: 100%"
              :key="tabKey0"
            >
              <el-table-column
                prop="contractCode"
                key="contractCode"
                label="合同号"
              >
                <template slot-scope="scope">
                  <div
                    class="name"
                    @click="toContract(scope.row)"
                  >
                    {{scope.row.contractCode}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="merchantCode"
                key="merchantCode"
                label="商户"
              >
              </el-table-column>
              <el-table-column
                prop="storeCode"
                key="storeCode"
                label="店铺"
              >
              </el-table-column>
              <el-table-column
                prop="brandName"
                key="brandName"
                label="品牌"
              >
                <template slot-scope="scope">
                  <div
                    class="name"
                    @click="toBrandDetail(scope.row)"
                  >
                    {{scope.row.brandName}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="brandClass"
                key="brandClass"
                label="品牌级次"
              >
              </el-table-column>
              <el-table-column
                prop="formats1Name"
                key="formats1Name"
                label="主营业态"
              >
              </el-table-column>
              <el-table-column
                prop="status"
                key="status"
                label="合同状态"
              >
              </el-table-column>
              <el-table-column
                prop="signDate"
                key="signDate"
                label="合同有效期"
              >
              </el-table-column>
            </el-table>
            <div class="w-page-sty">
              <el-pagination
                v-if="signList.total > 10"
                background
                @current-change="signedPageChange"
                layout="prev, pager, next"
                :page-size="signList.pageSize"
                :total="signList.total"
                :current-page="signedPageNum"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- 意向落位品牌 -->
        <div
          v-if="tabValue === 1"
          class="table-wrap"
        >
          <div class="w-singed-table">
            <el-table
              :data="intentionList.dataList"
              :key="tabKey1"
              style="width: 100%"
            >
              <el-table-column
                prop="brandName"
                key="brandName"
                label="品牌名称"
              >
                <template slot-scope="scope">
                  <div
                    class="name"
                    @click="toBrandDetail(scope.row)"
                  >
                    <span
                      v-if="scope.row.icon"
                      class="is-first"
                    >落</span>
                    {{scope.row.brandName}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="brandIndustry"
                key="brandIndustry"
                label="品牌业态"
              >
              </el-table-column>
              <el-table-column
                prop="brandClass"
                key="brandClass"
                label="品牌级次"
              >
              </el-table-column>
              <el-table-column
                prop="createUserName"
                key="createUserName"
                label="创建人"
              >
              </el-table-column>
              <el-table-column
                prop="createDateTime"
                key="createDateTime"
                label="创建时间"
              >
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    v-if="!scope.row.icon"
                    @click="showDelDialog(scope.row)"
                    type="text"
                    size="small"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="w-page-sty">
            <el-pagination
              v-if="intentionList.total > 10"
              background
              @current-change="intentionPageChange"
              layout="prev, pager, next"
              :page-size="intentionList.pageSize"
              :total="intentionList.total"
              :current-page="intentionPageNum"
            >
            </el-pagination>
          </div>
        </div>
        <!-- 跟进中品牌 -->
        <div
          v-if="tabValue === 2"
          class="table-wrap"
        >
          <div class="w-singed-table">
            <el-table
              :data="followBrandList.dataList"
              :key="tabKey2"
              style="width: 100%;"
            >
              <el-table-column
                prop="brandName"
                key="brandName"
                label="品牌名称"
              >
                <template slot-scope="scope">
                  <div
                    class="name"
                    @click="toBrandDetail(scope.row)"
                  >
                    {{scope.row.brandName}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="brandIndustry"
                key="brandIndustry"
                label="品牌业态"
              >
              </el-table-column>
              <el-table-column
                prop="brandClass"
                key="brandClass"
                label="品牌级次"
              >
              </el-table-column>
              <el-table-column
                prop="taskState"
                key="taskState"
                label="品牌跟进状态"
              >
              </el-table-column>
              <el-table-column
                prop="followUserName"
                key="followUserName"
                label="当前跟进人员"
              >
              </el-table-column>
              <el-table-column
                prop="lastFollowTime"
                key="lastFollowTime"
                label="最近跟进时间"
              >
              </el-table-column>
              <el-table-column
                prop="lastFollowMsg"
                key="lastFollowMsg"
                label="最新跟进信息"
              >
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    @click="toFollow(scope.row)"
                    type="text"
                    size="small"
                  >跟进</el-button>
                  <el-button
                    @click="showDelDialog(scope.row)"
                    type="text"
                    size="small"
                  >删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="w-page-sty">
              <el-pagination
                v-if="followBrandList.total > 10"
                background
                @current-change="signedPageChange"
                layout="prev, pager, next"
                :page-size="followBrandList.pageSize"
                :total="followBrandList.total"
                :current-page="brandPageNum"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 修改铺位招商负责人 -->
      <div
        v-if="dialogVisible"
        class="dialog-wrap"
      >
        <el-dialog
          title="修改铺位招商负责人"
          :visible.sync="dialogVisible"
          :show-close="false"
          width="400px"
          top="350px"
          custom-class="w-block-dialog"
          :before-close="handleClose"
        >
          <div class="input-wrap">
            <div class="title">招商负责人：</div>
            <div class="w-input-item height36-input">
              <el-select
                popper-class="w-block-select-down yellow-select-panel"
                v-model="principalId"
                @change="changePrincipal"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in principalList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="input-wrap mt15">
            <div class="title">铺位招商时效：</div>
            <div class="w-input-item height36-input">
              <el-date-picker
                popper-class="black-el-date-picker"
                value-format="yyyy-MM-dd"
                v-model="timeVal"
                type="daterange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="btns-wrap">
            <div class="btns">
              <span
                class="sure"
                @click="editClick()"
              >保存</span>
              <span
                class="cancal"
                @click="handleClose()"
              >取消</span>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 删除品牌 -->
      <div
        v-if="delDialog"
        class="dialog-wrap"
      >
        <el-dialog
          :visible.sync="delDialog"
          width="400px"
          top="350px"
          :show-close="false"
          custom-class="w-block-dialog"
          :before-close="closeDelDialog"
        >
          <div
            slot="title"
            class="headerTitle"
          >
            <svg
              style="width:21px;height:21px"
              t="1631695031620"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="9571"
              width="200"
              height="200"
            >
              <path
                d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                fill="#FF754C"
                p-id="9572"
              ></path>
              <path
                d="M463.286857 557.543619v-286.086095a48.761905 48.761905 0 1 1 97.52381 0v286.086095a48.761905 48.761905 0 0 1-97.52381 0z"
                fill="#FFFFFF"
                p-id="9573"
              ></path>
              <path
                d="M512 703.829333m-48.761905 0a48.761905 48.761905 0 1 0 97.52381 0 48.761905 48.761905 0 1 0-97.52381 0Z"
                fill="#FFFFFF"
                p-id="9574"
              ></path>
            </svg>
            提示
          </div>
          <div class="input-wrap">
            <div class="content">是否确认删除？</div>
          </div>
          <div class="btns-wrap">
            <div class="btns">
              <span
                class="sure"
                @click="checkDelete"
              >确认</span>
              <span
                class="cancal"
                @click="closeDelDialog"
              >取消</span>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 删除：二次确认框 -->
      <div
        v-if="delDialog2"
        class="dialog-wrap"
      >
        <el-dialog
          :visible.sync="delDialog2"
          :show-close="false"
          width="400px"
          top="350px"
          custom-class="w-block-dialog"
          :before-close="closeDelDialog2"
        >
          <div
            slot="title"
            class="headerTitle"
          >
            <svg
              style="width:21px;height:21px"
              t="1631695031620"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="9571"
              width="200"
              height="200"
            >
              <path
                d="M512 512m-512 0a512 512 0 1 0 1024 0 512 512 0 1 0-1024 0Z"
                fill="#FF754C"
                p-id="9572"
              ></path>
              <path
                d="M463.286857 557.543619v-286.086095a48.761905 48.761905 0 1 1 97.52381 0v286.086095a48.761905 48.761905 0 0 1-97.52381 0z"
                fill="#FFFFFF"
                p-id="9573"
              ></path>
              <path
                d="M512 703.829333m-48.761905 0a48.761905 48.761905 0 1 0 97.52381 0 48.761905 48.761905 0 1 0-97.52381 0Z"
                fill="#FFFFFF"
                p-id="9574"
              ></path>
            </svg>
            提示
          </div>
          <div class="input-wrap">
            <div class="content">该品牌正在招商中，删除后将停止本铺位对该品牌的招商。是否确认删除？</div>
          </div>
          <div class="btns-wrap">
            <div class="btns">
              <span
                class="sure"
                @click="toDelete"
              >确认</span>
              <span
                class="cancal"
                @click="closeDelDialog2"
              >取消</span>
            </div>
          </div>
        </el-dialog>
      </div>
      <!-- 跟进品牌 -->
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="investmentShow"
        direction="rtl"
      >
        <div v-if="investmentShow">
          <edit-states
            :invesmtentItem="invesmtentItem"
            @hideInvestmentEmit="hideInvestmentEmit"
          ></edit-states>
        </div>
      </el-drawer>
      <div v-if="maskShow">
        <add-mask
          v-if="projectId"
          :invesmtentItem="invesmtentItem"
          :projectId="projectId"
          :projectName="projectName"
          :maskEdit="maskEdit"
          @hideSingleMask="hideSingleMask"
        ></add-mask>
      </div>
      <!-- 发布招商任务、添加品牌跟进 -->
      <el-drawer
        class="black-drawer"
        size="856px"
        :with-header="false"
        :visible.sync="isInvestment"
        :before-close="beforeCloseBrandFollow"
        direction="rtl"
      >
        <div v-if="isInvestment && editType">
          <brandFollow
            @showAddBrand="showAddBrand"
            @selectProjectIdEmit="selectProjectIdEmit"
            @releaseBrandSuccessEmit="releaseBrandSuccessEmit"
            @hideBrandFollowEmit="hideBrandFollowEmit"
            :projectId="projectId"
            :editType="editType"
          ></brandFollow>
        </div>
        <el-drawer
          class="black-drawer"
          size="907px"
          :with-header="false"
          :append-to-body="true"
          :before-close="hideAddBrand"
          :visible.sync="addBrandSync"
        >
          <div v-if="projectIdcheck > 0">
            <addBrand
              @closeAddBrand="closeAddBrand"
              @addBrandEmit="addBrandEmit"
              :projectIdcheck="projectIdcheck"
              :editType="editType"
            ></addBrand>
          </div>
        </el-drawer>
      </el-drawer>
      <!-- 添加意向铺位 -->
      <el-drawer
        size="599px"
        :with-header="false"
        :visible.sync="isShowAddBrand"
        custom-class="w-block-dialog"
        direction="rtl"
      >
        <positionBrandManagement
          v-if="isShowAddBrand && projectId"
          :projectId='projectId'
          :dropEdit='dropEdit'
          :recommendObj='{}'
          :brandDetails='brandDetails'
          :delset='delset'
          :type="2"
          @hidePositionBrand='hidePositionBrand'
        ></positionBrandManagement>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import addBrand from '@/views/workBench/components/addBrand.vue' // 发布招商任务.vue
import brandFollow from '@/views/workBench/components/brandFollow.vue' // 发布招商任务.vue
import positionBrandManagement from '@/views/workBench/components/positionBrandManagement.vue' // 添加意向铺位.vue
import editStates from '@/views/workBench/components/investmentEditStates.vue'
import addMask from '@/views/workBench/components/addSingleMask.vue'
import Bus from '@/utils/bus.js'
import { getCookie, powerAuth } from '@/utils'
export default {
  components: {
    addBrand,
    brandFollow,
    positionBrandManagement,
    editStates,
    addMask
  },
  data () {
    return {
      loading: true,
      modulesList: [],
      berthInfo: {},
      brandDetails: {},
      berthRentInfo: {},
      tabKey0: '',
      tabKey1: '',
      tabKey2: '',
      tabValue: 0,
      tabList: [
        {
          label: '铺位签约信息',
          value: 0
        },
        {
          label: '意向落位品牌',
          value: 1
        },
        {
          label: '跟进中品牌',
          value: 2
        }
      ],
      btnsList: [
        {
          name: '添加签约品牌',
          icon: 'icon-signed'
        },
        {
          name: '发布招商任务',
          icon: 'icon-investment'
        },
        {
          name: '添加品牌跟进',
          icon: 'icon-brand-follow'
        },
        {
          name: '添加意向品牌',
          icon: 'icon-add-intentionalBrand'
        }
      ],
      signList: { // 铺位签约信息
        pageNum: '',
        pageSize: '',
        total: '',
        dataList: []
      },
      signedPageNum: 1,
      intentionList: { // 意向落位品牌
        pageNum: '',
        pageSize: '',
        total: '',
        dataList: []
      },
      intentionPageNum: 1,
      followBrandList: { // 跟进中品牌
        pageNum: '',
        pageSize: '',
        total: '',
        dataList: []
      },
      brandPageNum: 1,
      // 修改招商负责人弹框
      principalList: [],
      principalId: '',
      principalName: '',
      timeVal: '',
      dialogVisible: false,
      temp: {
        id: '',
        name: '',
        time: []
      },
      // 删除弹框
      delId: '',
      delDialog: false,
      delDialog2: false,
      // 发布招商任务
      projectIdcheck: 0,
      isInvestment: false,
      addBrandSync: false,
      editType: 1,
      // 添加品牌跟进
      invesmtentItem: {},
      investmentShow: false,
      maskShow: false,
      maskEdit: 0,
      // 添加意向品牌
      isShowAddBrand: false,
      dropEdit: 0,
      delset: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  props: {
    projectId: {
      type: Number
    },
    projectName: {
      type: String
    }
  },
  watch: {
    projectId (o) {
      this.projectIdcheck = o
      this.getPrincipalList()
    }
  },
  mounted () {
    if (powerAuth(216)) {
      this.getModularListFunc()
      this.getBerthInfo()
      this.getBerthRentInfo()
      this.getSignList()
      this.getIntentionList()
      this.getFollowBrandList()
      if (this.projectId > 0) {
        this.projectIdcheck = this.projectId
        this.getPrincipalList()
      }
    } else {
      this.loading = false
    }
  },
  methods: {
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    getPrincipalList () {
      this.axios.post(api.getFollowList, { projectId: this.projectId })
        .then((res) => {
          this.principalList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
        })
    },
    getBerthInfo () {
      this.axios.post(api.berthDetailsData, { berthId: this.$route.query.id }).then(res => {
        this.berthInfo = res.data.data
        this.brandDetails.berth = res.data.data
        this.principalId = res.data.data.principalId ? res.data.data.principalId : ''
        this.principalName = res.data.data.principalName ? res.data.data.principalName : ''
        if (res.data.data.brandBerthTime) {
          this.timeVal = res.data.data.brandBerthTime.replace(/\s*/g, '').split('~')
        }
        this.getPrincipalList()
        this.loading = false
      })
    },
    getBerthRentInfo () {
      this.axios.post(api.getRentPlan, { berthId: this.$route.query.id }).then(res => {
        if (res.data.code === 0 && res.data.data) {
          this.berthRentInfo = res.data.data
        }
      })
    },
    getSignList () {
      this.tabKey0 = Math.random().toFixed(2)
      const params = {
        berthId: this.$route.query.id,
        pageNum: this.signedPageNum,
        pageSize: 10
      }
      this.axios.post(api.shopSigningInformation, params).then(res => {
        this.$set(this.signList, 'pageNum', res.data.data.pageNum)
        this.$set(this.signList, 'pageSize', res.data.data.pageSize)
        this.$set(this.signList, 'total', res.data.data.total)
        const Arr = res.data.data.dataList.map(item => {
          const obj = {
            brandClass: item.brandClass ? item.brandClass : '-',
            brandName: item.brandName ? item.brandName : '-',
            contractCode: item.contractCode ? item.contractCode : '-',
            brandId: item.brandId ? item.brandId : '',
            gbid: item.gbid ? item.gbid : '',
            berthType: item.berthType ? item.berthType : '',
            contractId: item.contractId ? item.contractId : '',
            cutoffDate: item.cutoffDate ? item.cutoffDate : '-',
            effectDate: item.effectDate ? item.effectDate : '-',
            formats1Name: item.formats1Name ? item.formats1Name : '-',
            merchantCode: item.merchantCode ? item.merchantCode : '-',
            signDate: item.signDate ? item.signDate : '-',
            storeCode: item.storeCode ? item.storeCode : '-'
          }
          if (item.status === '1') {
            obj.status = '未完善'
          } else if (item.status === '2') {
            obj.status = '新增待确认'
          } else if (item.status === '3') {
            obj.status = '审核未通过'
          } else if (item.status === '4') {
            obj.status = '审批中'
          } else if (item.status === '5') {
            obj.status = '已确认'
          } else if (item.status === '6') {
            obj.status = '已转为正式合同'
          } else if (item.status === '7') {
            obj.status = '已终止'
          } else if (item.status === '8') {
            obj.status = '终止待确认'
          } else if (item.status === '9') {
            obj.status = '终止已确认'
          } else if (item.status === '10') {
            obj.status = '变更待确认'
          } else if (item.status === '11') {
            obj.status = '已清算 '
          } else {
            obj.status = '- '
          }
          return obj
        })
        this.$set(this.signList, 'dataList', Arr)
      })
    },
    getIntentionList () {
      this.tabKey1 = Math.random().toFixed(4)
      const params = {
        berthId: this.$route.query.id,
        pageNum: this.intentionPageNum,
        pageSize: 10,
        projectId: this.projectId
      }
      this.axios.post(api.intentionBrandLevel, params).then(res => {
        this.$set(this.intentionList, 'pageNum', res.data.data.pageNum)
        this.$set(this.intentionList, 'pageSize', res.data.data.pageSize)
        this.$set(this.intentionList, 'total', res.data.data.total)
        const Arr = res.data.data.dataList.map(item => {
          const obj = {
            brandId: item.brandId ? item.brandId : '-',
            brandClass: item.brandClass ? item.brandClass : '-',
            gbid: item.gbid ? item.gbid : '-',
            brandIndustry: item.brandIndustry ? item.brandIndustry : '-',
            brandName: item.brandName ? item.brandName : '-',
            createDateTime: item.createDateTime ? item.createDateTime : '-',
            createUserName: item.createUserName ? item.createUserName : '-',
            icon: item.icon
          }
          return obj
        })
        this.$set(this.intentionList, 'dataList', Arr)
      })
    },
    getFollowBrandList () {
      this.tabKey2 = Math.random().toFixed(6)
      const params = {
        berthId: this.$route.query.id,
        pageNum: this.brandPageNum,
        pageSize: 10
      }
      this.axios.post(api.followingBrand, params).then(res => {
        this.$set(this.followBrandList, 'pageNum', res.data.data.pageNum)
        this.$set(this.followBrandList, 'pageSize', res.data.data.pageSize)
        this.$set(this.followBrandList, 'total', res.data.data.total)
        const Arr = res.data.data.dataList.map(item => {
          const obj = {
            brandId: item.brandId ? item.brandId : '-',
            gbid: item.gbid ? item.gbid : '-',
            taskId: item.taskId ? item.taskId : '-',
            brandClass: item.brandClass ? item.brandClass : '-',
            brandIndustry: item.brandIndustry ? item.brandIndustry : '-',
            brandName: item.brandName ? item.brandName : '-',
            followUserName: item.followUserName ? item.followUserName : '-',
            lastFollowMsg: item.lastFollowMsg ? item.lastFollowMsg : '-',
            lastFollowTime: item.lastFollowTime ? item.lastFollowTime : '-',
            taskState: item.taskState ? item.taskState : '-'
          }
          return obj
        })
        this.$set(this.followBrandList, 'dataList', Arr)
      })
    },
    showEditInfoDialog () {
      if (!powerAuth(216, 'setprincipal')) {
        this.hasnotPermission()
        return false
      }
      this.temp.id = this.principalId ? this.principalId : ''
      this.temp.name = this.principalName ? this.principalName : ''
      this.temp.time = this.timeVal ? this.timeVal : ''
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
      this.principalId = this.temp.id
      this.principalName = this.temp.name
      this.timeVal = this.temp.time
    },
    changePrincipal (val) {
      this.principalId = val
      const Arr = this.principalList.filter(item => {
        return item.value === val
      })
      this.principalName = Arr[0].label
    },
    editClick () {
      const params = {
        berthFinishTime: this.timeVal ? this.timeVal[1] : '',
        berthId: this.$route.query.id,
        berthStartTime: this.timeVal ? this.timeVal[0] : '',
        principalId: this.principalId,
        principalName: this.principalName,
        projectId: this.projectId
      }
      this.axios.post(api.updatePrincipal, params).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.getBerthInfo()
        } else {
          this.$message({
            type: 'warning',
            message: res.data.msg
          })
        }
      })
      this.dialogVisible = false
    },
    btnClick (index) {
      switch (index) {
        case 0: // 添加签约品牌
          this.addSinged()
          break
        case 1: // 发布招商任务
          if (!powerAuth(217, 'pubplan')) {
            this.hasnotPermission()
            return false
          }
          this.$store.dispatch('setInvestmentBrandList', [])
          this.editType = index
          this.isInvestment = true
          break
        case 2: // 添加品牌跟进
          if (!powerAuth(217, 'addfollow')) {
            this.hasnotPermission()
            return false
          }
          this.$store.dispatch('setInvestmentBrandList', [])
          this.editType = index
          this.isInvestment = true
          break
        case 3: // 添加意向品牌
          this.isShowAddBrand = true
          break
      }
    },
    tabSelect (index) {
      this.tabValue = index
    },
    signedPageChange (val) {
      this.signedPageNum = val
      this.getSignList()
    },
    intentionPageChange (val) {
      this.intentionPageNum = val
      this.getIntentionList()
    },
    brandPageChange (val) {
      this.brandPageNum = val
      this.getFollowBrandList()
    },
    addSinged () {
      if (!powerAuth(215, 'addsignbrand')) {
        this.hasnotPermission()
        return false
      }
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + getCookie('wisdom_token'), '_blank')
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    },
    getModularListFunc () {
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    // 跟进品牌
    toFollow (item) {
      this.invesmtentItem = item
      this.invesmtentItem.brand = item
      this.invesmtentItem.task = item
      this.invesmtentItem.taskId = item.taskId
      if (item.berths && item.berths.length > 0) {
        this.invesmtentItem.floor = item.berths[0].floor
        this.invesmtentItem.berthNo = item.berths[0].berthNo
        this.invesmtentItem.berthId = item.berths[0].berthId
      }
      this.investmentShow = true
    },
    // 删除品牌校验
    showDelDialog (item) {
      this.delDialog = true
      this.delId = item.gbid
    },
    closeDelDialog () {
      this.delDialog = false
      this.delId = ''
    },
    closeDelDialog2 () {
      this.delDialog2 = false
      this.delId = ''
    },
    checkDelete () {
      const params = {
        gbid: this.delId,
        projectId: this.projectId,
        berthId: this.$route.query.id
      }
      this.axios.post(api.intentionBrandLevelDelete, params)
        .then((res) => {
          if (res.data.code === 0 && res.data.data) {
            this.delDialog = false
            this.delDialog2 = true
          } else if (res.data.code === 0 && res.data.data === 0) {
            this.toDelete()
          }
        })
    },
    toDelete () {
      this.delDialog = false
      const params = {
        gbid: this.delId,
        isDelete: 1,
        projectId: this.projectId,
        berthId: this.$route.query.id,
        isFollow: this.tabValue === 2 ? 1 : 0
      }
      this.axios.post(api.berthDeleteBrand, params)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.delDialog2 = false
            this.delId = ''
            if (this.tabValue === 1) { // 意向落位品牌删除
              this.getIntentionList()
            } else { // 跟进中品牌删除
              this.getFollowBrandList()
            }
          }
        })
    },
    // 发布招商任务
    hideAddBrand () {
      this.addBrandSync = false
    },
    selectProjectIdEmit (id) {
      this.projectIdcheck = id
    },
    closeAddBrand () {
      this.addBrandSync = false
    },
    addBrandEmit () {
      this.addBrandSync = false
    },
    showAddBrand () {
      this.addBrandSync = true
    },
    hideBrandFollowEmit () {
      Bus.$emit('removeAddBrandListBusEmit')
      this.isInvestment = false
    },
    beforeCloseBrandFollow () {
      Bus.$emit('removeAddBrandListBusEmit')
      this.isInvestment = false
    },
    releaseBrandSuccessEmit () {
      this.isInvestment = false
      this.getFollowBrandList()
    },
    // 添加品牌跟进
    hideInvestmentEmit () {
      this.getFollowBrandList()
      this.investmentShow = false
    },
    hideSingleMask () {
      this.maskShow = false
      document.body.style.overflowY = 'auto'
    },
    // 添加意向品牌
    hidePositionBrand () {
      this.getIntentionList()
      this.isShowAddBrand = false
    },
    // 意向落位品牌查看品牌详情
    toBrandDetail (item) {
      if (!powerAuth(164)) {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
        return false
      }
      if (item.brandId !== 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      }
    },
    toContract (item) {
      if (this.modulesList[0].flag) {
        if (this.modulesList[0].userFlag) {
          if (item.berthType === '1') { // 铺位
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          } else if (item.berthType === '2') { // 广告位
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/advertContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          } else if (item.berthType === '3') { // 场地
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/siteContract/details/mainBodyInfo?contractCode=' + item.contractCode + '&contractId=' + item.contractId + '&mallCode=' + this.projectId + '&menuCode=12&operateStep=3&tokenval=' + getCookie('wisdom_token'), '_blank')
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，此账号暂无权限！'
          })
        }
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，集团暂未开通此应用模块！'
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-berth-investment-wrap
  margin-left 204px
  width calc(100% - 226px)
  padding-bottom 16px
.info-wrap
  display flex
  justify-content space-between
  margin-bottom 24px
  padding 40px
  height 197px
  color #fff
  font-size 14px
  font-family PingFang SC
  font-weight 400
  background #272930
  border-radius 2px
  .info
    flex 1
    .info-line1
      margin-bottom 24px
      display flex
      align-items center
      flex-wrap wrap
      height 37px
      line-height 37px
      .berthNo
        margin-right 12px
        font-size 26px
      .state
        margin-right 24px
        color #2AC8A8
    .info-line2
      display flex
      flex-wrap wrap
      span
        margin-right 32px
        color #fff
    .mb-16
      margin-bottom 16px
    .mr-16
      margin-right 16px
    .mr-32
      margin-right 32px
    .title
      min-width 150px
      color #808191
      text
        color #fff
    .btn
      width 68px
      height 28px
      text-align center
      line-height 28px
      cursor pointer
      border 1px solid #F89407
      border-radius 4px
      font-weight bold
      color #F89407
  .status
    height 78px
    line-height 78px
    text-align center
    font-size 20px
    color #FFFFFF
    border 1px dashed #808191
    border-radius 8px
  .w150
    width 150px
  .w100
    width 100px
.w-table-content
  background-color #272930
  height 174px
  .w-screen-title
    display flex
    align-items center
  .w-berth-info
    margin-top 37px
    padding 0 32px
    margin-bottom 32px
    table
      width 100%
      border 1px solid #373A43
      border-collapse collapse
      tr
        height 36px
        vertical-align middle
        border-bottom 1px solid #373A43
        td
          width 152px
          text-align center
          font-size 14px
          color #fff
        td:nth-child(2n+1)
          width 124px
          color #B2B3BD
          background-color #2C2E38
.tab-wrap
  margin-top 24px
  background #272930
  .tab
    float left
    height 88px
    width 100%
    text-align center
    line-height 88px
    font-size 16px
    color #fff
    display inline-block
    background #272930
    border-radius 2px
    ul
      float left
      list-style none
      li
        margin 0 45px
        float left
        cursor pointer
        &:hover span:first-child
          color #fff
          display block
          height 86px
          line-height 86px
          border-bottom 2px solid #FFA134
        span
          font-size 16px
          color #A5A5A7
      li:last-child
        margin 0 0 0 45px
  .active>span
    display block
    height 86px
    line-height 86px
    font-weight bold
    color #fff !important
    border-bottom 2px solid #FFA134
  .btns-wrap
    span
      cursor pointer
      margin-right 32px
      &:hover
        color #f89407
        i:before
          color #f89407
    & span:last-child
      margin-right 24px
  .table-wrap
    padding-bottom 24px
    .w-singed-table
      box-shadow 0px 6px 12px 0px rgba(0, 0, 0, 0.04)
      box-sizing border-box
      padding-left 32px !important
      padding-right 32px !important
      padding-bottom 32px
      // 表格样式
      & >>> .el-table
        font-size 14px
        font-weight 400
        background-color #272930 !important
      & >>> .el-table::before
        background-color #373A43 !important
      & >>> .el-table th
        border-top 1px solid #373A43 !important
        border-bottom 1px solid #373A43 !important
        background-color #272930 !important
        color #B2B3BD !important
      & >>> .el-table tr
        border-bottom 1px solid #373A43 !important
        background-color #272930 !important
        color #808191 !important
      & >>> .el-table td
        border-bottom 1px solid #373A43 !important
      & >>> .el-table__fixed-right::before
        background-color unset !important
      & >>> .el-table tr:hover > td
        background #23252b !important
      .is-first
        display inline-block
        border 1px solid #fff
        width 20px
        height 20px
        text-align center
        line-height 20px
        color #fff
        border-radius 50%
        font-size 12px
      .name
        cursor pointer
        color #fff
        text-decoration underline
        &:hover
          color #f89407
.dialog-wrap
  .headerTitle
    display flex
    align-items center
    font-size 18px
    font-weight bold
    color #fff
    svg
      margin-right 13px
  .input-wrap
    display flex
    align-items center
    .title
      width 98px
      color #fff
      font-size 14px
      text-align right
    .height36-input
      width 254px
      /deep/ .el-date-editor--daterange.el-input__inner
        width 254px
        border 1px solid #373A43
        background #272930
        & input
          background #272930
      /deep/ .el-input input
        width 254px
        border 1px solid #373A43
        background #272930
    .content
      color #fff
      font-size 14px
  .btns-wrap
    display flex
    margin-top 28px
    .btns
      margin-left auto
      span
        display inline-block
        width 88px
        height 36px
        line-height 36px
        text-align center
        color #fff
        border-radius 4px
        cursor pointer
        &.sure
          background-color #F89407
          &:hover
            background-color #DB8307
        &.cancal
          background-color #373A43
          margin-left 24px
@media screen and (max-width 1367px)
  .tab-wrap
    .tab
      & ul li
        margin 0 14px !important
      .btns-wrap span
        margin-right 14px !important
</style>
